import { useEffect } from 'react';
import './../App.css';

function Footer(){

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>가다을</div>
                    <div className='footer__txt-con'>
                        <div className='footer__txt02'>
                            <span>©</span> Gadaeul. All Rights Reserved.
                        </div>
                    </div>
                    <div className='footer__law-con'>
                        <a href='/license'>오픈소스 라이선스</a>
                    </div>
                    <div style={{height:'60px'}}></div>
                </div>
            </div>
        </>
    )
}

export default Footer;