import { useEffect, useState } from 'react';
import './../App.css';
import MenuBar from './Menu/MenuBar';
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// Document
import jsondata from './../data/documents/intro.json'
import { useNavigate } from 'react-router-dom';



export default function Intro(){
    const navigate = useNavigate();
    localStorage.setItem('category', '전체카테고리')
    const data = jsondata.data;
    

    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리


    // Click Series & Boook
    const clickSeries = (el) => {
        localStorage.setItem('book_series', el);
    }
    const clickNumber = (el) => {
        localStorage.setItem('book_number', el);
        navigate('/product')
    }
    // Initialize index
    useEffect(()=>{
        localStorage.setItem('book_series', 0)
        localStorage.setItem('book_number', 0)
    });

    return(
        <>
            <div className='frame'>
                {/* <img src='/images/bg_gold02.jpg' decoding='async' /> */}
                <div className='frame__con'>
                    <MenuBar />
                    <div className='intro__content-con'>
                        <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={1}
                        loop={true}
                        // pagination={true}
                        navigation
                        // pagination={{ clickable: true }}
                        autoplay={{delay: 12000, disableOnInteraction: true}}
                        // scrollbar={{ draggable: true }}
                        >
                            {data.map((a,i)=>{
                                return(
                                    <SwiperSlide className='intro__swiperslide'>
                                        <div className='intro__content-desc-con'>
                                            <ul className='intro__content-desc-txt-con'>
                                                <li>
                                                    {a.title01}
                                                    {/* 말로하는 가다을 어순체화 */}
                                                </li>
                                                <li dangerouslySetInnerHTML={{__html: a.title02}}>
                                                    {/* 어순체화가 되면,<br/>영어식 사고가 된다 */}
                                                </li>
                                            </ul>
                                            <div className='intro__content-desc-img-con'>
                                                <img src={`/images/${a.thumbnail}`} />
                                                <div>
                                                    {a.desc01}
                                                    {/* 위계, 확장, 대비의 원리를 통한 학습자 중심의 학습서 */}
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html: a.desc02}}>
                                                    {/* EFL 환경에서 확실하게 실력을 올리는 방법! 매일 매일 가다을 하세요. */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='intro__content-product-con'>
                                            <div className='intro__content-product-title'>
                                                [{a.type}]
                                                {/* [어순체화북] */}
                                            </div>
                                            <ul className='intro__content-product-img-con' onClick={()=>{clickSeries(i)}}>
                                            <Swiper
                                            modules={[Navigation, Pagination, Autoplay]}
                                            spaceBetween={30}
                                            slidesPerView={displayMo.matches ? 3 : 5}
                                            loop={true}
                                            pagination={true}
                                            // navigation
                                            // pagination={{ clickable: true }}
                                            autoplay={{delay: 2000, disableOnInteraction: true}}
                                            // scrollbar={{ draggable: true }}
                                            >
                                                {a.images.map((a,i)=>{
                                                    return(
                                                        <SwiperSlide>
                                                            <li onClick={()=>{clickNumber(i)}}>
                                                                <img src={`images/${a}`} />
                                                            </li>
                                                        </SwiperSlide>
                                                    )
                                                })}
                                            </Swiper>
                                                
                                            </ul>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}