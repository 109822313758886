import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../App.css';

export default function Header(props){

    // scroll event
    const [scrollPosition, setScrollPosition] = useState(null);
    const [clickHamburger, setClickHamburger] = useState(null);

    // 스크롤 위치 인식
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    // 무한 스크롤 방지하기 위해서 100ms마다 스크롤 위치 체크
    useEffect(()=>{
        const timer = setTimeout(window.addEventListener('scroll', updateScroll), 100)       
        return () => {
            clearTimeout(timer);
        };
    });

    // 햄버거 버튼 클릭시 메뉴 보이기
    const showList = () => {
        setClickHamburger(!clickHamburger);
    };

    return (
        <>
            <header className={`header header-unclick`}>
                <div className='header__con'>
                    {/* 로고 */}
                    <ul className='header__logo-con'>
                        <li className='header__logo'>
                            <a href='/'>
                                <span>EFL 환경에서 영어 실력을 올리는 최고의 학습법</span>
                                <span>가다을 ENGLISH</span>
                            </a>
                        </li>
                        <li className='header__hamburger'><a><img src={clickHamburger ? `/icons/btn_x.png` : `/icons/btn_hamburger.png`} onClick={showList}/></a></li>
                    </ul>
                    
                    {/* 메뉴 */}
                    <ul className='header__category-con'>
                        <li>
                            <a href=''>
                                회사소개
                            </a>
                        </li>
                        <li>
                            <a href=''>
                                로그인
                            </a>
                        </li>
                        <li>
                            <a href=''>
                                회원가입
                            </a>
                        </li>
                        <li>
                            <a href=''>
                                마이페이지
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <div className={`header-mo ${clickHamburger ? 'left-0' : '' }`}>
                <ul className='header-mo__category-con'>
                    <li>
                        <a href=''>
                            회사소개
                        </a>
                    </li>
                    <li>
                        <a href=''>
                            로그인
                        </a>
                    </li>
                    <li>
                        <a href=''>
                            회원가입
                        </a>
                    </li>
                    <li>
                        <a href=''>
                            마이페이지
                        </a>
                    </li>
                </ul>
            </div>

            <div className='header-block'></div>
            <div className='bg-gray' style={{display: clickHamburger? 'block' : 'none'}} onClick={showList}></div>
        </>
    )
}
