import { useEffect, useState } from 'react';
import './../../App.css';



export default function MenuBar(){
    const [category, setCategory] = useState(null);
    useEffect(() => {
        setCategory(localStorage.getItem('category'))
    }, [])


    return(
        <>
            <ul className='menubar'>
                <a href='/' className={category == '전체카테고리' ? 'menubar-after' : ''}><li>전체<br/>카테고리</li></a>
                <a href='/product' className={category == '교재소개' ? 'menubar-after' : ''}><li>교재소개</li></a>
                <a className='none-mo'><li clas>교재활용</li></a>
                <a className='none-mo'><li>부가자료</li></a>
                <a className='none-mo'><li>연구</li></a>
                <a className='none-mo'><li>교재주문</li></a>
                <a className='none-mo'><li>이벤트</li></a>
            </ul>
        </>
    )
}