import { useEffect, useState } from 'react';
import './../../App.css';
import MenuBar from './MenuBar';
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// Document
import jsondata from './../../data/documents/product.json'


export default function Product(){
    localStorage.setItem('category', '교재소개')
    const data = jsondata.data;

    const [series, setSeries] = useState(localStorage.getItem('book_series') || 0);
    const [number, setNumber] = useState(localStorage.getItem('book_number') || 0);

    return(
        <>
            <div className='frame'>
                {/* <img src='/images/bg_admin.jpg' decoding='async' /> */}
                <div className='frame__con'>
                    <MenuBar />

                    <div className='product__select-con'>
                        <select
                            onChange={(e) => {setSeries(e.target.value)}}
                        >
                            {['어순체화 Series','Core Grammar Series'].map((a,i)=>{
                                return(<option value={i}>{a}</option>)
                            })}
                        </select>
                    </div>

                    <div className='product__content-con'>
                        <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={1}
                        loop={true}
                        // pagination={true}
                        navigation
                        // pagination={{ clickable: true }}
                        autoplay={{delay: 5000, disableOnInteraction: true}}
                        // scrollbar={{ draggable: true }}
                        initialSlide={number}
                        >
                            {data[series].map((a,i)=>{
                                return(
                                    <SwiperSlide className='product__swiperslide'>
                                        <div className='product__content-img-wrap'>
                                            <img src={`/images/${a.image}`} />
                                        </div>

                                        <div className='product__content-txt-con'>
                                            <ul className='product__content-txt-top'>
                                                <li dangerouslySetInnerHTML={{__html: a.title01}}>
                                                    {/* 말로하는 가다을 어순체화 LEVEL1 */}
                                                </li>
                                                <li dangerouslySetInnerHTML={{__html: a.title02}}>
                                                    {/* 어순체화가 되면,<br/>영어식 사고가 된다 */}
                                                </li>
                                            </ul>

                                            <ul className='product__content-txt-mid'>
                                                <li dangerouslySetInnerHTML={{__html: a.desc01}}>
                                                    {/* 위계, 확장, 대비의 원리를 통한 학습자 중심의 학습서 */}
                                                </li>
                                                <li dangerouslySetInnerHTML={{__html: a.desc02}}>
                                                    {/* EFL 환경에서 확실하게 실력을 올리는 방법! 매일 매일 가다을 하세요. */}
                                                </li>
                                            </ul>

                                            <ul className='product__content-txt-bot'>
                                                <li dangerouslySetInnerHTML={{__html: a.detail}}>
                                                    {/* 주어가 자주 생략되는 한국어와 다르게,<br/>
                                                    주어를 반드시 밝혀 써야 하는 영어 문장의 기본을 익힐 수 있도록,<br/>
                                                    1형식과 3형식 문형을 체화하는 단계입니다. */}
                                                </li>
                                            </ul>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}